( () => {

	// Block Name
	const blockName = 'next-section';
	// Environment
	const method = ( document.querySelector( 'body' ) === null || document.querySelector( 'body' ).classList.contains( 'wp-admin' ) ) ? 'wp-admin' : 'front';
	/**
	 * Initialize Block
	 *
	 * @param  {node} block
	 *
	 * @return {mixed}
	 */
	const initializeBlock = ( block ) => {

		if ( block[ 0 ] !== undefined ) {

			block = block[ 0 ].querySelector( `.b-${ blockName }` );

		}
		if ( block === false || block === null ) {

			return false;

		}
		/////////////////////
		// BLOCK CODE HERE //
		/////////////////////
		return block;

	}
	/**
	 * Get all blocks
	 *
	 * @return {void}
	 */
	const getBlocks = () => {

		const blocks = document.querySelectorAll( `.b-${ blockName }` );
		if ( blocks.length > 0 ) {

			blocks.forEach( ( block ) => {

				if ( block !== false && block !== null ) {

					initializeBlock( block );

				}

			} );

		}

	}
	/**
	 * Run on frontend
	 *
	 * @param  {string} method
	 *
	 * @return {void}
	 */
	if ( method === 'front' ) {

		document.addEventListener( 'DOMContentLoaded', getBlocks );

	}
	/**
	 * Run on backend
	 *
	 * @param  {string} method
	 *
	 * @return {void}
	 */
	if ( method === 'wp-admin' ) {

		window.addEventListener( 'load', () => {

			if ( window.acf ) {

				getBlocks();
				// Add update block action for ACF
				window.acf.addAction( `render_block_preview/type=${ blockName }`, initializeBlock );

			}

		} );

	}

} )();